<template>
  <setup-table
    :title="`Additional Vehicle Charge`"
    :items="items"
    :headers="headers"
    :loading="loading"
    :clearItem="!showForm || !editedItem.id"
    @createItem="createItem()"
    @editItem="editItem"
    @deleteItems="deleteItems"
  >
    <template #itemForm v-if="showForm">
      <additional-vehicle-charges-form
        ref="additionalVehicleChargesForm"
        :value="editedItem"
        @submit="saveItem"
        @closeForm="closeForm"
      ></additional-vehicle-charges-form>
    </template>

    <template #[`item.chargeRate`]="{ item }">
      <p class="mb-0">${{ item.chargeRate }}</p>
    </template>
  </setup-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_ADDITIONAL_VEHICLE_CHARGES, SAVE_ADDITIONAL_VEHICLE_CHARGE, DELETE_ADDITIONAL_VEHICLE_CHARGES } from '@/store/modules/AdditionalVehicleCharge/actions';
import SetupTable from './SetupTable.vue';
import AdditionalVehicleChargesForm from './AdditionalVehicleChargesForm.vue';
import { toMoney } from '@/util';

export default {
  name: 'AdditionalVehicleChargesSetupTable',
  inject: ['eventHub'],
  components: { SetupTable, AdditionalVehicleChargesForm },
  data() {
    return {
      toMoney,
      showForm: false,
      items: [],
      editedItem: {},
      loading: true,
      headers: [
        { text: 'Charge Type', value: 'chargeType' },
        { text: 'Charge Amount', value: 'chargeRate' },
      ],
    };
  },
  computed: {
    ...mapGetters('additionalVehicleCharge', ['additionalVehicleCharges']),
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('additionalVehicleCharge', [GET_ADDITIONAL_VEHICLE_CHARGES, SAVE_ADDITIONAL_VEHICLE_CHARGE, DELETE_ADDITIONAL_VEHICLE_CHARGES]),
    async fetchItems() {
      await this.getAdditionalVehicleCharges({ forTable: true });
      this.items = this.additionalVehicleCharges;
      this.loading = false;
    },
    createItem() {
      this.showForm = true;
      this.editedItem = { id: 0};
    },
    editItem(item) {
      this.editedItem = { ...item };
      this.showForm = true;
    },
    async saveItem(item) {
      try {
        const r = await this.saveAdditionalVehicleCharge(item);
        if (r && r.id) {
          this.$myalert.success('Additional Vehicle Charge saved');
          await this.fetchItems();
          this.editedItem = { ...item, id: r.id };
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async deleteItems(items) {
      const yes = await this.$myconfirm(
        `Are you sure you want to delete ${
          items.length > 1 ? 'these Additional Vehicle Charges' : 'this Additional Vehicle Charge'
        }? This operation cannot be undone.`
      );
      if (yes) {
        const r = await this.deleteAdditionalVehicleCharges(items.map((e) => e.id));
        if (r.done) {
          this.$myalert.success(`${items.length > 1 ? 'Additional Vehicle Charges' : 'Additional Vehicle Charge'} deleted`);
          this.showForm = false;
          this.fetchItems();
        }
      }
    },
    closeForm() {
      this.showForm = false;
    },
  },
};
</script>

<style lang="scss">
.table-action {
  margin-left: 10px;
}
</style>
